<template>
  <div id="datos-tramite">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-text style="background: #eee">
          <v-row class="mt-2">
            <v-col cols="12" md="12" sm="12">
              <base-material-card color="primary" class="px-5 py-3">
                <template v-slot:heading>
                  <div class="text-h5 font-weight-light my-2">
                    {{
                      form.numero
                        ? "Datos del Trámite: " + form.numero
                        : "Datos del Trámite"
                    }}
                    {{
                      form.fecha ? " - Fecha: " + formatDate(form.fecha) : null
                    }}
                  </div>
                </template>
                <div
                  class="text-h4 font-weight-light mx-2 my-2 red--text text-right"
                >
                  <p class="blue--text font-weight-light mx-2 text-right">
                    <b>
                      ATENCIÓN: Los datos se guardarán al avanzar a la siguiente
                      sección</b
                    >
                  </p>
                </div>
                <v-card-text>
                  <v-row class="mx-0 justify-center">
                    <v-col cols="12" md="3" sm="12" class="centered-input">
                      <v-text-field
                        v-model="form.numero_certificado"
                        :readonly="readonly"
                        label="Número de Certificado"
                        :rules="[$rulesRequerido, $rulesNumeroCertificado]"
                        v-mask="'##-########/####'"
                        hint="Ingrese el número con el formato 99-99999999/9999"
                        maxLength="16"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <v-autocomplete
                        :items="tipo_certificados"
                        item-value="id"
                        item-text="nombre"
                        label="Tipo de Certificado"
                        v-model="form.tipo_certificado_id"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <v-autocomplete
                        :items="tipo_tramites"
                        item-value="id"
                        item-text="nombre"
                        label="Tipo de Trámite"
                        v-model="form.tipo_tramite_id"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 justify-center">
                    <v-col
                      cols="12"
                      :md="tipoF01 == 'Autos' ? 3 : 3"
                      sm="12"
                      class="centered-input"
                    >
                      <v-text-field
                        v-model="form.chasis"
                        :readonly="readonly"
                        label="Número de Chasis"
                        hint="Debe ingresar los últimos 7 dígitos"
                        maxLength="7"
                        :rules="[$rulesRequerido, $rulesChasis]"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="tipoF01 == 'Autos'" cols="12" md="3" sm="12">
                      <v-autocomplete
                        :items="tipo_vehiculos"
                        item-value="id"
                        item-text="nombre"
                        label="Tipo de Vehículo"
                        v-model="form.tipo_vehiculo_id"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" :md="tipoF01 == 'Autos' ? 3 : 4" sm="12">
                      <v-autocomplete
                        :items="itemsSN"
                        item-value="id"
                        item-text="detalle"
                        label="Certifica Firma Comerciante"
                        v-model="form.certifica_firma"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" :md="tipoF01 == 'Autos' ? 2 : 4" sm="12">
                      <v-autocomplete
                        :items="itemsSN"
                        item-value="id"
                        item-text="detalle"
                        label="Sociedad de Hecho"
                        v-model="form.sociedad_hecho"
                        :rules="[$rulesRequerido]"
                        :readonly="readonly"
                        @change="esSociedad()"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 justify-center">
                    <v-col
                      cols="12"
                      md="3"
                      sm="12"
                      class="centered-input col-f12"
                      style="flex: 0 0 23.67%"
                    >
                      <v-checkbox
                        v-model="requiere_f12"
                        label="No requiere F12"
                        filled
                        @change="form.formulario12 = ''"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="5" sm="12">
                      <v-text-field
                        v-model="form.formulario12"
                        :readonly="readonly"
                        :disabled="requiere_f12"
                        label="N° Formulario 12"
                        :rules="
                          !requiere_f12
                            ? [$rulesRequerido, $rulesNumericos]
                            : []
                        "
                        maxLength="8"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3" sm="12" class="centered-input">
                      <!--<v-text-field
                        v-model="form.titulares"
                        :readonly="readonly"
                        label="Cantidad de titulares"
                        :rules="[$rulesRequerido, $rulesEnterosPositivos]"
                        maxLength="3"
                        hint="Ingrese la cantidad de titulares para registrar la información en siguiente Sección"
                        filled
                        dense
                        @focus="
                          oldTitulares = form.titulares ? form.titulares : null
                        "
                        @change="changeCantidadTitulares()"
                      ></v-text-field>-->
                      <v-text-field
                        v-model="form.titulares"
                        :readonly="readonly"
                        label="Cantidad de titulares"
                        :rules="[$rulesRequerido, $rulesEnterosPositivos]"
                        maxLength="3"
                        hint="Ingrese la cantidad de titulares para registrar la información en siguiente Sección"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row> </v-row>
                </v-card-text>
              </base-material-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right" cols="12" md="12" sm="12">
              <v-btn color="red" @click="$exit(idTramite)">Salir</v-btn>
              <v-btn color="primary" @click="next()">Siguiente</v-btn>
            </v-col>
          </v-row>
          <br />
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.right-input >>> input {
  text-align: right;
}
.chip-indicadores {
  color: "blue-grey--text darken-4";
  font-size: 14px;
  /*font-family: 'Material Icons';*/
  /*font-weight: bold;*/
  font-style: normal;
}

.primary-change {
  color: #fff !important;
  /*background-color: #a7a8a8 !important;*/
  background-color: #105cfa !important;
  font-size: 20px;
  font-weight: bold;
  height: 70px !important;
  display: flex;
  align-items: center;
}

.col-f12 {
  text-align: center;
  font-size: 2rem;
  /*border-radius: 28px;*/
  font-style: #a0a0a0 !important;
  color: #a0a0a0 !important;
  font-weight: 500 !important;
  border-bottom: solid;
  border-color: #011f5b;
  /*border-radius: 28px;*/
  /*background: #eee;*/
  padding: 8px 0px 23px 17px;
  height: 55px;
  margin-top: 7px;
  margin-left: 7px !important;
  margin-right: 15px;
}
.centered-input >>> input {
  text-align: center;
  font-size: 1.5rem;
  color: #105cfa !important;
  margin-top: 16px;
  border-bottom: solid;
  border-color: #011f5b;
}
</style>
<script>
export default {
  name: "DatosTramite",
  components: {},
  props: {
    tramite: { Object, default: null },
    tipoF01: { String, default: null },
    readonly: { Boolean, default: false },
    idTramite: { Number, default: null },
    keyProp: { Number, default: null }, // Define una prop para recibir la clave desde el componente padre
  },
  data: () => ({
    mask: "##:##",

    requiere_f12: false,
    money: {
      decimal: ",",
      thousands: ".",
      suffix: "",
      precision: 2,
      length: 11,
      masked: false,
    },
    options: {
      locale: "es-AR",
      prefix: "$",
      suffix: "",
      length: 11,
      precision: 2,
    },
    properties: {
      hint: "Ingrese solo numeros",
      rules: [(v) => !!v || "Requerido"],
    },

    snackbar: false,
    color: "success",
    timeout: 4000,
    text: "Se ha registrado una nueva solicitud",
    search: "",
    tipos_operaciones: [
      /*{ id: 1, detalle: "Nuevo Trámite" },
							        { id: 2, detalle: "Continuar Operación" },*/
    ],
    tipo_tramites: [
      /*{ id: 1, detalle: "S/prenda - Autos" },
							        { id: 2, detalle: "C/prenda - Autos" },
							        { id: 3, detalle: "S/prenda - Mavi" },
							        { id: 4, detalle: "C/prenda - Mavi" },*/
    ],
    tipo_certificados: [],
    itemsSN: [
      { id: "S", detalle: "SI" },
      { id: "N", detalle: "NO" },
    ],
    tipo_vehiculos: [],
    valid: true,
    autoGrow: true,
    rows: 5,
    nowDate: new Date().toISOString().slice(0, 10),
    menu: false,
    form: {},
    oldTitulares: null,
  }),

  methods: {
    changeCantidadTitulares() {
      //console.log(this.oldTitulares);
      //console.log(this.form.titulares);
      if (this.oldTitulares) {
        if (this.form.titulares) {
          if (Number(this.form.titulares) < Number(this.oldTitulares)) {
            Swal.fire({
              title: "<b>Modificar Cantidad de Titulares?</b>",
              icon: "question",
              html:
                "Si reduce la cantidad de titulares (<b>" +
                Number(this.form.titulares) +
                "</b>), se perderan los datos previamente cargados (<b>" +
                Number(this.oldTitulares) +
                "</b>). Confirma la modificación?",
              showCloseButton: false,
              showCancelButton: true,
              focusConfirm: true,
              confirmButtonColor: "green",
              cancelButtonColor: "#d33",
              confirmButtonText: "Aceptar",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                this.oldTitulares = this.form.titulares;
              } else {
                this.form.titulares = this.oldTitulares;
              }
            });
          }
        }
      } else {
        this.oldTitulares = Number(this.form.titulares);
      }
    },
    next() {
      this.form.tipo_operacion_id = 1;
      //this.$emit("send-message", "2", "1", this.form);
      //console.log(this.requiere_f12);
      //if (this.$refs.form.validate() && this.primeravez) {
      if (this.$refs.form.validate()) {
        this.form.requiere_f12 = this.requiere_f12 == true ? true : false;
        //this.$emit("send-message", "4", "1", this.form);
        this.$emit("send-message", "2", "1", this.form);
        //this.primeravez = false;
      } else {
        this.snackbar = true;
        this.text = "Verifique los datos ingresados y/o campos obligatorios";
        this.color = "error";
      }
    },
    setDatosPrueba() {
      console.log("set datos prueba");
      this.form.numero_certificado = 12832322222333;
      this.form.tipo_certificado_id = 1;
      this.form.tipo_tramite_id = 1;
      this.form.chasis = "2345679";
      this.form.tipo_vehiculo_id = 1;
      this.form.certifica_firma = "S";
      this.form.sociedad_hecho = "N";
      this.requiere_f12 = false;
      this.form.formulario12 = "16565555";
      this.form.titulares = 1;
    },
    getTiposOperaciones() {},

    esSociedad() {
      if (this.form.sociedad_hecho == "S") {
        this.form.titulares = 3;
        this.$forceUpdate();
      }
    },
  },
  computed: {},
  created() {
    console.log("Componente Datos Tramite creado");

    if (this.$route.params.id > 0 && this.idTramite > 0 && this.keyProp == 1) {
      this.$getData(["tipo_tramites", "tipo_certificados", "tipo_vehiculos"]);
    }
    if (
      typeof this.$route.params.id == "undefined" &&
      this.idTramite == null &&
      this.keyProp == 0
    ) {
      this.$getData(["tipo_tramites", "tipo_certificados", "tipo_vehiculos"]);
    }
    //this.$getListForSelect("tipo_certificados")
    /*this.$getListForSelect("tipo_tramites");
    this.$getListForSelect("tipo_certificados");
    this.$getListForSelect("tipo_vehiculos");*/

    this.valid = true;
  },

  watch: {},

  mounted() {
    //console.log("mounted");
    //---------------CASO DE PRUEBA---------------------//
    this.setDatosPrueba();
    //---------------CASO DE PRUEBA---------------------//

    if (
      this.$root.$children[0].$children[0].$children[0].$children[0].$vnode
        .componentOptions.tag == "dashboard-core-app-bar"
    )
      this.$root.$children[0].$children[0].$children[0].$children[0].setDrawer(
        false
      );
    if (
      this.$root.$children[0].$children[0].$children[0].$children[1].$vnode
        .componentOptions.tag == "dashboard-core-app-bar"
    )
      this.$root.$children[0].$children[0].$children[0].$children[1].setDrawer(
        false
      );

    if (this.tramite.id) {
      //console.log(Number(this.tramite.requiere_f12));
      this.form = this.tramite;
      this.form.tipo_certificado_id = Number(this.tramite.tipo_certificado_id);
      this.form.tipo_tramite_id = Number(this.tramite.tipo_tramite_id);
      this.form.tipo_vehiculo_id = Number(this.tramite.tipo_vehiculo_id);
      this.requiere_f12 = Number(this.tramite.requiere_f12) == 1 ? true : false;
      this.oldTitulares = Number(this.tramite.titulares);
    }
  },
};
</script>
